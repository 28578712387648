import type { Config } from '../types';
import { ScriptSigningMethod } from '../types';

export const config: Config = {
  brand: 'juniper',
  locale: 'en-AU',
  quizLanguage: 'EN_AU',
  mimsLogoUrl: 'https://assets.pilot.com.au/mims-logo.png',
  mimsUrl: 'https://www.mims.com.au/',
  pathologyEnabled: true,
  scriptSigningMethods: [ScriptSigningMethod.WET_SIGNING],
  enableLogoutOnInactivity: true,
  supportsPatientMessaging: true,
  supportsPhotoRequests: ['WEIGHT_LOSS'],
  showAdminNotesInPrescribeModal: false,
  rejection: {
    enableRejectionReasonPlaceholderInLetter: true,
    enableRejectionReasonsForm: true,
  },
  tracking: {
    enabled: true,
    problemTypes: ['WEIGHT_LOSS'],
  },
  validateShippingAddressWithinRange: true,
  diabeteseRiskLevelEnabled: true,
  showCallPatientButton: true,
  callProvider: 'ZOOM',
  callRequirementDisclaimerEnabled: true,
  dateOfBirthFormat: 'dd/MM/yyyy',
  medicareEnabled: true,
  problemTypes: ['MENOPAUSE', 'WEIGHT_LOSS'],
  countryCode: 'AU',
};
