import { FaQuestionCircle } from 'react-icons/fa';
import type { ReactNode } from 'react';
import type React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

export interface TooltipProps {
  content: ReactNode;
  name: string;
  link?: string;
}

export const Tooltip = ({
  content,
  link,
  name,
}: TooltipProps): React.ReactElement => (
  <>
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      data-tip
      data-tooltip-id={`${name}-tooltip`}
    >
      <FaQuestionCircle className="inline align-top text-sm" />
    </a>
    <ReactTooltip id={`${name}-tooltip`}>{content}</ReactTooltip>
  </>
);
