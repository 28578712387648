import { InactivityTimeoutManager } from 'inactivity-timeout-manager';
import 'shared/logging';
import 'shared/real-user-monitoring';
import { GoogleAuthProvider } from 'providers/google-auth';
import { IdentityProvider } from 'providers/identity';
import type React from 'react';
import ReactDOM from 'react-dom';
import { ChatProvider } from 'providers/chat';
import { I18nProvider } from 'providers/i18n-provider';
import { getTranslationFile } from 'lang';
import { ErrorBoundary } from 'error-boundary';
import { ClientProvider } from './apollo';
import { AuthProvider } from './auth';
import { GlobalLoaderProvider } from './global-loader';
import { Navigation } from './navigation';
import { NotificationsProvider } from './shared/components/notifications';
import * as serviceWorker from './serviceWorker';
import './tailwind.generated.css';
import { useTitle } from './shared/hooks/use-title';
import { WrappingFeatureFlagProvider } from './wrapping-ff-provider';
import { MaintenanceModeWrapper } from './maintenance-mode-wrapper';

const App = (): React.ReactElement => {
  useTitle();

  return (
    <I18nProvider getTranslationFile={getTranslationFile}>
      <ErrorBoundary>
        <GlobalLoaderProvider>
          <GoogleAuthProvider>
            <NotificationsProvider>
              <AuthProvider>
                <ClientProvider>
                  <WrappingFeatureFlagProvider>
                    <MaintenanceModeWrapper>
                      <IdentityProvider>
                        <ChatProvider>
                          <InactivityTimeoutManager>
                            <Navigation />
                          </InactivityTimeoutManager>
                        </ChatProvider>
                      </IdentityProvider>
                    </MaintenanceModeWrapper>
                  </WrappingFeatureFlagProvider>
                </ClientProvider>
              </AuthProvider>
            </NotificationsProvider>
          </GoogleAuthProvider>
        </GlobalLoaderProvider>
      </ErrorBoundary>
    </I18nProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
