import type { Environment } from './types';
import { sharedProdEnv, sharedPilotEnv, sharedProdAuEnv } from './shared';

export const env: Environment = {
  ...sharedProdEnv,
  ...sharedPilotEnv,
  ...sharedProdAuEnv,
  adminAppUrl: 'https://admin.pilot.com.au',
  logoUrl: 'https://assets.pilot.com.au/pilot-logo.svg',
};
