import type { ReactNode } from 'react';
import React from 'react';
import className from 'classnames';
import { Label } from 'shared/components/label';
import { InputError } from 'shared/components/input-error';
import clsx from 'clsx';

interface Props {
  label?: string;
  placeholder?: string;
  defaultValue?: string | number;
  type?: string;
  name: string;
  errorMessage?: string | JSX.Element;
  autoFocus?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  readOnly?: boolean;
  step?: number;
  min?: number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  icon?: ReactNode;
}

const BaseInput = (
  {
    label,
    placeholder,
    type = 'text',
    name,
    errorMessage,
    autoFocus,
    disabled = false,
    hidden = false,
    defaultValue,
    step,
    min,
    readOnly = false,
    onChange,
    icon,
  }: Props,
  ref: React.Ref<HTMLInputElement>,
): React.ReactElement => (
  <>
    {label && (
      <div className="mb-3">
        <Label htmlFor={name}>{label}</Label>
      </div>
    )}
    <div className="relative">
      <input
        autoFocus={autoFocus}
        id={name}
        ref={ref}
        name={name}
        className={className(
          'appearance-none border w-full px-3 py-2 text-slate-700 focus:outline-none transition-shadow duration-200',
          {
            'pr-8': !!icon && type !== 'search', // Compensates for icon
            'pl-8': type === 'search',
            'rounded-md': true,

            'border-red-500 focus:border-red-500 focus:shadow-error':
              errorMessage,

            'border-slate-300 focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-40':
              !errorMessage && type !== 'search',

            'border-slate-300 focus:border-slate-900 focus:border-2':
              !errorMessage && type === 'search',

            'bg-slate-100 text-slate-600 cursor-not-allowed pointer-events-none':
              disabled || readOnly,

            'bg-white text-slate-700': !disabled && !readOnly,
          },
        )}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        defaultValue={defaultValue}
        step={step}
        min={min}
        hidden={hidden}
        onChange={onChange}
      />
      {icon && (
        <div
          className={clsx(
            'absolute top-1/4 pt-1',
            { 'left-3': type === 'search' },
            { 'right-4': type !== 'search' },
          )}
        >
          {icon}
        </div>
      )}
    </div>
    <InputError>{errorMessage}</InputError>
  </>
);

export const Input = React.forwardRef<HTMLInputElement, Props>(BaseInput);
